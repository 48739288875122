<template>
    <div class="full-height">
        <div v-if="isVerifying" class="text-center">
            <div class="mt-2 mb-3">
                <svg xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.0" width="64px" height="64px" viewBox="0 0 128 128" xml:space="preserve"><rect x="0" y="0" width="100%" height="100%" fill="#F2F3F7" /><g transform="translate(128,0) scale(-1,1)"><circle cx="16" cy="64" r="16" fill="#7cc6d6"/><circle cx="16" cy="64" r="16" fill="#a8d9e4" transform="rotate(45,64,64)"/><circle cx="16" cy="64" r="16" fill="#c8e7ee" transform="rotate(90,64,64)"/><circle cx="16" cy="64" r="16" fill="#e5f4f7" transform="rotate(135,64,64)"/><circle cx="16" cy="64" r="16" fill="#f0f8fa" transform="rotate(180,64,64)"/><circle cx="16" cy="64" r="16" fill="#f0f8fa" transform="rotate(225,64,64)"/><circle cx="16" cy="64" r="16" fill="#f0f8fa" transform="rotate(270,64,64)"/><circle cx="16" cy="64" r="16" fill="#f0f8fa" transform="rotate(315,64,64)"/><animateTransform attributeName="transform" type="rotate" values="45 64 64;90 64 64;135 64 64;180 64 64;225 64 64;270 64 64;315 64 64;0 64 64" calcMode="discrete" dur="720ms" repeatCount="indefinite"></animateTransform></g></svg>
            </div>
            <div>Please wait..your code is being verified.</div>
        </div>
        <div v-else class="text-center">
            <h4>{{responseMsg}}</h4>
        </div>
    </div>
</template>

<script>
import API from '../../../utils/API'
export default {
    data(){
        return {
            isVerifying: true,
            responseMsg: ''
        }
    },
    async mounted(){
        if(this.$route.query.code){
            let res = await API.post('/custom/booking/verify-provider-selection-code', {payload_code : this.$route.query.code })
            this.responseMsg = res.data.msg
        } else if(this.$route.query.request && this.$route.query.request == 'newprovider'){
            let res = await API.post('/custom/booking/verify-provider-selection-code', { request: 'newprovider', booking_id : this.$route.params.bookingid })
            this.responseMsg = res.data.msg
        } else {
            this.responseMsg = 'Sorry, there is no url code provided. You might clicked the wrong url.'
        }
        this.isVerifying = false
    }
}
</script>

<style scoped>
.full-height {
    height: 100vh;
}
</style>